import { gql } from "@apollo/client";

export const LINEITEM_CHECKOUT_FRAGMENT = gql`
  fragment CheckoutLineItemFragment on CheckoutLineItem {
    id
    title
    quantity
    discountAllocations {
      allocatedAmount {
        ...PriceFragment
      }
      discountApplication {
        ... on AutomaticDiscountApplication {
          __typename
          title
          targetType
          targetSelection
        }
        ... on DiscountCodeApplication {
          __typename
          code
          targetType
          targetSelection
        }
        ... on ScriptDiscountApplication {
          __typename
          title
          targetType
          targetSelection
        }
      }
    }
    variant {
      id
      title
      image {
        altText
        id
        transformedSrc
      }
      priceV2 {
        ...PriceFragment
      }
      compareAtPriceV2 {
        ...PriceFragment
      }
    }
  }
`;

export const PRICE_FRAGMENT = gql`
  fragment PriceFragment on MoneyV2 {
    amount
    currencyCode
  }
`;


export const CHECKOUT_FRAGMENT = gql`
  fragment CheckoutFragment on Checkout {

    id
    createdAt
    completedAt
    orderStatusUrl
    lineItemsSubtotalPrice {
      amount
    }
    discountApplications(first: 250) {
      edges {
        node {
          targetSelection
          targetType
          value {
            ... on MoneyV2 {
              __typename
              amount
              currencyCode
            }
            ... on PricingPercentageValue {
              __typename
              percentage
            }
          }
          ... on AutomaticDiscountApplication {
            __typename
            targetSelection
            title
            targetType
            value {
              ... on MoneyV2 {
                __typename
                amount
                currencyCode
              }
            }
          }
          ... on DiscountCodeApplication {
            __typename
            targetSelection
            targetType
            code
            value {
              ... on MoneyV2 {
                __typename
                amount
                currencyCode
              }
            }
          }
          ... on ScriptDiscountApplication {
            __typename
            title
            targetSelection
            targetType
            value {
              ... on MoneyV2 {
                __typename
                amount
                currencyCode
              }
            }
          }
        }
      }
    }
    lineItems(first: 250) {
      edges {
        node {
          ...CheckoutLineItemFragment
        }
      }
    }
    webUrl
    totalPriceV2 {
      amount
      currencyCode
    }
  }
`;
