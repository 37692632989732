import React, { Component } from 'react';
import { ApolloProvider } from '@apollo/client';
import CartMini from './CartMini';
import ShowPrice from '../components/ShowPrice';
import Variants from '../components/Variants';
import AddToCartButton from '../components/AddToCartButton';
import FavoriteButton from '../components/FavoriteButton';
import FillBundleImage from '../components/FillBundleImage';
import { VariantProvider } from '../components/VariantContext';
import ShopifyHelper from '../helpers/ShopifyHelper';
import FavoriteCount from '../components/FavoriteCount';

export default class Cart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      prices: [],
      variants: [],
      contextVariant: {},
      addBtns: [],
      favoriteBtns: [],
      cartOverview: <></>,
      fillBundleImages: null,
    };

    this.fillPrices = this.fillPrices.bind(this);
    this.getVariants = this.getVariants.bind(this);
    this.fillBuyBtns = this.fillBuyBtns.bind(this);
    this.fillFavorites = this.fillFavorites.bind(this);
    this.fillBundles = this.fillBundles.bind(this);
  }

  componentDidMount() {
    this.fillBuyBtns();
    this.fillFavorites();
    this.fillPrices();
    this.getVariants();
    this.fillBundles();
  }

  fillFavorites() {
    const { localStorageKey } = this.props;

    const favoritesStorageKey = localStorageKey + '-wishlist';
    const favoriteIds = JSON.parse(localStorage.getItem(favoritesStorageKey));

    const favoriteProductElements = document.querySelectorAll(
      '.shopify-favorite-button[data-product-id]'
    );

    let favoriteBtns = [];
    favoriteProductElements.forEach((element, i) => {
      const {
        onWishList,
        productId
      } = element.dataset;
      favoriteBtns.push(
        <FavoriteButton
          key={i}
          element={element}
          productId={productId}
          isFavorited={
            favoriteIds !== null ? favoriteIds.includes(productId) : false
          }
          onWishList={onWishList === 'true'}
          localStorageKey={favoritesStorageKey}
        ></FavoriteButton>
      );
    });

    this.setState({ favoriteBtns: favoriteBtns });
  }

  fillBuyBtns() {
    const addToCartElements = document.querySelectorAll(
      '.add-to-cart-button[data-product-id]'
    );

    let addBtns = [];
    addToCartElements.forEach((element, i) => {
      const variantId = element.getAttribute('data-variant-id');
      const isDetailButton = element.classList.contains('detail-add-to-cart-button');
      if (!element.dataset.childOfPrice) {
        addBtns.push(
          <AddToCartButton
            key={i}
            element={element}
            isDetailButton={isDetailButton}
            onCartPage={element.dataset.onCartPage === 'true'}
            variantId={variantId}
          ></AddToCartButton>
        );
      }
    });

    this.setState({ addBtns: addBtns });
  }

  fillPrices() {
    const priceElements = document.querySelectorAll(
      '.price-of-variant[data-product-id]'
    );
    let prices = [];

    priceElements.forEach((element, i) => {
      const isDetailPrice = element.classList.contains('detail-add-to-cart-price');
      const { variantId, productId, showBackground, iconOnly, notifyMeClass } = element.dataset;
      prices.push(
        <ShowPrice
          element={element}
          key={i}
          isDetailPrice={isDetailPrice}
          variantId={variantId}
          productId={productId}
          footerNotify={false}
          showBackground={showBackground}
          iconOnly={iconOnly === 'true'}
          notifyMeClass={notifyMeClass}
        />
      );
    });
    const priceVariantFooter = document.querySelector('.price-of-variant-footer[data-product-id]');
    if (priceVariantFooter) {
      const footerVariantId = priceVariantFooter.getAttribute('data-variant-id');
      const footerProductId = priceVariantFooter.getAttribute('data-product-id');
      const addToCartButtonFooterWrapper = document.querySelector(`.add-to-cart-button-wrapper-sticky-footer[data-variant-id="${footerVariantId}"]`);
      prices.push(
        <ShowPrice
          element={priceVariantFooter}
          key={'show-price-variant-footer'}
          isDetailPrice={true}
          variantId={footerVariantId}
          productId={footerProductId}
          addToCartButtonWrapper={addToCartButtonFooterWrapper}
          footerNotify={true}
          notifyMeClass={priceVariantFooter.getAttribute('data-notify-me-class')}
          iconOnly={false}
        ></ShowPrice>
      )
    }

    const priceVariantBuyCtaItems = document.querySelectorAll('.price-of-variant-buy-cta[data-product-id]');
    if (priceVariantBuyCtaItems.length) {
      priceVariantBuyCtaItems.forEach((priceVariantBuyCta, i) => {
        const footerVariantId = priceVariantBuyCta.getAttribute('data-variant-id');
        const footerProductId = priceVariantBuyCta.getAttribute('data-product-id');
        const addToCartButtonFooterWrapper = document.querySelector(`.add-to-cart-button-wrapper-buy-cta[data-variant-id="${footerVariantId}"]`);
        prices.push(
          <ShowPrice
            element={priceVariantBuyCta}
            key={`show-price-variant-buy-cta--${i}`}
            isDetailPrice={true}
            variantId={footerVariantId}
            productId={footerProductId}
            footerNotify={false}
            notifyMeClass={'button button--white'}
            showBackground={'false'}
            iconOnly={false}
          ></ShowPrice>
        )
      })
    }

    this.setState({ prices: prices });
  }

  getVariants() {
    const variantElements = document.querySelectorAll('.product-variants');
    let variants = [];
    variantElements.forEach((element, i) => {
      const { productId, variantId, enabledVariants } = element.dataset;
      // Set default variant id
      const hashedId = ShopifyHelper.getStorefrontId(variantId);
      this.setState({contextVariant: {variantId: hashedId}});
      variants.push(
        <Variants
          productId={productId}
          enabledVariants={enabledVariants}
          selectedVariant={variantId}
          key={`variants-${productId}`}
          element={element}
        ></Variants>
      );
    });
    this.setState({ variants: variants });
  }

  fillBundles() {
    const bundleElements = document.querySelectorAll(
      '.bundle-shopify-img[data-product-id]'
    );

    bundleElements.forEach((element) => {
      const productId = element.getAttribute('data-variant-id');

      if (productId) {
        this.setState({
          fillBundleImages: <FillBundleImage element={element} id={productId}></FillBundleImage>
        });
      }
    });
  }

  setContextVariant = (contextVariant) => {
    this.setState((prevState) => ({contextVariant}));
  }

  render() {
    const {
      prices,
      variants,
      productOptions,
      addBtns,
      cartOverview,
      favoriteBtns,
      fillBundleImages,
      contextVariant,
    } = this.state;

    const { setContextVariant } = this;

    return (
      <>
        <ApolloProvider client={this.props.client}>
          <VariantProvider value={{
            contextVariant,
            setContextVariant
          }}>
            <CartMini></CartMini>
            {favoriteBtns}
            <FavoriteCount elements={this.props.favoriteCountElements} localStorageKey={this.props.localStorageKey}/>
            {prices}
            {variants}
            {productOptions}
            {addBtns}
            {cartOverview}
            {fillBundleImages}
          </VariantProvider>
        </ApolloProvider>
      </>
    );
  }
}
